<template>
  <div style="text-align: justify">
    <h2 class="mb-4">Buffers - Prelab Exercise</h2>

    <p class="mb-2">
      Please enter your exercise number below. Without this number, you will automatically receive a
      grade of 0 on your prelab. If this does not match the number on the prelab you hand in, you
      will receive a grade of 0.
    </p>

    <calculation-input
      v-model="inputs.exerciseNumber"
      class="mb-5"
      prepend-text="$\text{Exercise Number:}$"
      :disabled="!allowEditing"
    />

    <p class="mb-4">
      A buffer solution is prepared as described in (a) below. To a sample of this buffer solution
      is added a solution of a strong base as described in (b).
    </p>

    <p v-if="exNumber >= 101 && exNumber <= 900" class="mb-3 pl-6">
      (a) 100 mL of <latex-number :number="concHA.toFixed(2)" unit="\text{M}" /> benzoic acid are
      mixed with <latex-number :number="molA" unit="\text{mol}" /> of sodium benzoate plus 100 mL of
      water.
    </p>

    <p v-else class="mb-3 pl-6">
      (a) <span style="font-weight: bold; color: #c50000">Invalid Exercise Number</span>
    </p>

    <p v-if="exNumber >= 101 && exNumber <= 900" class="mb-6 pl-6">
      (b) 1.0 mL of <latex-number :number="concKOH.toFixed(2)" unit="\text{M}" /> KOH is added to 50
      mL of the solution described in part (a).
    </p>

    <p v-else class="mb-6 pl-6">
      (b) <span style="font-weight: bold; color: #c50000">Invalid Exercise Number</span>
    </p>

    <p class="mb-3">For each of these solutions, calculate:</p>

    <p class="mb-2 pl-6">(i) the molar concentration of benzoic acid</p>

    <p class="mb-2 pl-6">(ii) the molar concentration of benzoate ion</p>

    <p class="mb-4 pl-6">(iii) the pH of the resulting solution</p>

    <p class="mb-5">
      <b>Note</b>. The K<sub>a</sub> of benzoic acid is
      <stemble-latex content="$6.5\times10^{-5}$" />
    </p>

    <p class="mb-3">Solution (a)</p>

    <calculation-input
      v-model="inputs.concHASoln1"
      class="mb-3"
      prepend-text="$\ce{[Benzoic Acid]}:$"
      append-text="$\text{M}$"
      dense
      :disabled="!allowEditing"
    />

    <calculation-input
      v-model="inputs.concASoln1"
      class="mb-3"
      prepend-text="$\ce{[Benzoate]}:$"
      append-text="$\text{M}$"
      dense
      :disabled="!allowEditing"
    />

    <calculation-input
      v-model="inputs.phSoln1"
      class="mb-5"
      prepend-text="$\text{pH}:$"
      dense
      :disabled="!allowEditing"
    />

    <p class="mb-3">Solution (b)</p>

    <calculation-input
      v-model="inputs.concHASoln2"
      class="mb-3"
      prepend-text="$\ce{[Benzoic Acid]}:$"
      append-text="$\text{M}$"
      dense
      :disabled="!allowEditing"
    />

    <calculation-input
      v-model="inputs.concASoln2"
      class="mb-3"
      prepend-text="$\ce{[Benzoate]}:$"
      append-text="$\text{M}$"
      dense
      :disabled="!allowEditing"
    />

    <calculation-input
      v-model="inputs.phSoln2"
      class="mb-5"
      prepend-text="$\text{pH}:$"
      dense
      :disabled="!allowEditing"
    />

    <p>
      In the lab manual in the section of "Making a Buffer to a Given pH", the buffer you will be
      required to prepare will have a pH of
      <latex-number :number="requiredPH.toFixed(2)" unit="\text{.}" /> Make note of this value and
      the prelab exercise number on your report sheet in the lab manual.
    </p>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import LatexNumber from '../displayers/LatexNumber';
import ChemicalLatex from '../displayers/ChemicalLatex';
import {seededRandomBetween} from '@/courses/utils/seededShuffle';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'UPEIBuffersPrelabV2',
  components: {
    CalculationInput,
    ChemicalLatex,
    StembleLatex,
    NumberValue,
    LatexNumber,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        exerciseNumber: null,
        concHASoln1: null,
        concASoln1: null,
        phSoln1: null,
        concHASoln2: null,
        concASoln2: null,
        phSoln2: null,
      },
    };
  },
  computed: {
    exNumber() {
      if (this.inputs.exerciseNumber) {
        return this.inputs.exerciseNumber;
      } else {
        return 0;
      }
    },
    concHA() {
      return seededRandomBetween(this.exNumber, 0.1, 0.2, 2);
    },
    concKOH() {
      return seededRandomBetween(this.exNumber, 0.45, 0.55, 2);
    },
    requiredPH() {
      return seededRandomBetween(this.exNumber * 3, 4.0, 4.5, 2);
    },
    molA() {
      return (this.concHA / 10).toFixed(3);
    },
  },
};
</script>
